import { TPageInput } from 'Models/history/isPageInput';
import { TSortInput } from 'Models/request/isSortInput';
import URI from 'urijs';

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;
const DEFAULT_JOBS_SORT_BY: TSortInput[] = [
	{ direction: 'DESC', field: 'completedDate' },
	{ direction: 'ASC', field: 'jobStatus' },
];
const DEFAULT_HOURS_SORT_BY: TSortInput[] = [
	{ direction: 'DESC', field: 'startTime' },
];
const DEFAULT_HARVEST_SORT_BY: TSortInput[] = [
	{ direction: 'DESC', field: 'date' },
];
const DEFAULT_INVENTORY_SORT_BY: TSortInput[] = [
	{ direction: 'ASC', field: 'productName' },
];
const DEFAULT_INVENTORY_MOVEMENT_SORT_BY: TSortInput[] = [
	{ direction: 'DESC', field: 'date' },
];

function getDefaultSortBy(segment?: string): TSortInput[] {
	switch (segment) {
		case 'jobs':
			return DEFAULT_JOBS_SORT_BY;
		case 'hours':
			return DEFAULT_HOURS_SORT_BY;
		case 'harvest':
			return DEFAULT_HARVEST_SORT_BY;
		case 'current':
			return DEFAULT_INVENTORY_SORT_BY;
		case 'movement':
			return DEFAULT_INVENTORY_MOVEMENT_SORT_BY;
		default:
			return [];
	}
}

const pageInputFromQuery = (query: any): TPageInput => {
	const { pl, po, psb } = query;
	const logsSegment = URI(window.location.href).segment(3);

	const limit = pl ? Number(pl) : DEFAULT_LIMIT;
	const offset = po ? Number(po) : DEFAULT_OFFSET;
	const sortBy = psb || getDefaultSortBy(logsSegment);

	return {
		limit,
		offset,
		sortBy,
	};
};

export default pageInputFromQuery;
