import React from 'react';

import Breakpoints from 'UI/theme/breakpoints';
import useBreakpoint from 'UI/utils/useBreakpoint';

import Template from '../../../../../components/DashboardPageTemplate';
import SearchMap from '../SearchMap';
import Breadcrumbs from './../../../common/components/Breadcrumbs';

export default function PageBody() {
	const isTablet = useBreakpoint(Breakpoints.TABLET);

	return (
		<Template>
			<Template.Navigation>
				<Breadcrumbs />
			</Template.Navigation>
			<Template.Board>
				<Template.Card size={isTablet ? 6 : 8} noPadding noHeaderBorder>
					<SearchMap />
				</Template.Card>
			</Template.Board>
		</Template>
	);
}
