import React, { FC, useCallback, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { analyticsTrack, TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useLocation from 'Features/router/hooks/useLocation';
import { TExportType } from 'Models/exports/ExportType';
import { ReportType, TReportFormat } from 'Models/exports/Report';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import { LogTypes } from 'Models/logs/LogType';
import Permissions from 'Models/permissions/Permissions';
import Divider from 'UI/display/Divider';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';
import useProfile from 'Hooks/useProfile';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';
import generateReport from 'Utils/generateReport';
import throwErrorSilently from 'Utils/throwErrorSilently';
import truncateString from 'Utils/truncateString';

import SubscriptionModal from '../../../components/SubscriptionModal';
import { ReportFormInterface, TCreateReportType } from './CreateReport.types';
import { CreateReportModal } from './CreateReportModal';
import { makeReportBody } from './CreateRport.utils';

const MAX_REPORT_NAME_LENGTH = 40;
export const CreateReportButton: FC = () => {
	const { t } = useTranslation();
	const { profile } = useProfile();
	const { getAccessTokenSilently } = useAuth0();
	const location = useCurrentLocation();
	const locationID = useLocationID();
	const [reportType, setReportType] = useState<TCreateReportType | null>(null);
	const [isMenuOpened, setMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const toggleMenu = useCallback((event) => {
		setMenuOpened((prevState) => {
			prevState ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
			return !prevState;
		});
	}, []);

	const canPurchase = usePermission(Permissions.JOB_LOGS_PURCHASE);

	const hasCuadernoDigitalSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.CUADERNO_DIGITAL
	);
	const hasFarmableProSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.FARMABLE_PRO
	);

	const hasValidSubscription =
		hasCuadernoDigitalSubscription || hasFarmableProSubscription;

	const toggleModal = (reportType?: TCreateReportType) => {
		setReportType(reportType || null);
	};

	const trackExport = (type: TExportType) => {
		if (profile) {
			analyticsTrack(TrackEvents.DataExported, {
				userId: profile.id,
				groupId: locationID,
				exportType: type,
				logType: LogTypes.Jobs,
			});
		}
	};

	const openModal = () => {
		if (profile && location) {
			analyticsTrack(TrackEvents.ProductViewed, {
				userId: profile.id,
				groupId: location.id,
				farmName: location.name,
				moduleName:
					location.type === 'SIEX'
						? SubscriptionType.CUADERNO_DIGITAL
						: SubscriptionType.FARMABLE_PRO,
			});
		}
	};

	const cuadernoDigitalStripeProduct = process.env
		.STRIPE_CUADERNO_DIGITAL_PRODUCT as string;
	const farmableProProduct = process.env.STRIPE_FARMABLE_PRO_PRODUCT as string;

	const productIdsWithDemo =
		location?.type === 'SIEX'
			? [{ productId: cuadernoDigitalStripeProduct }]
			: [{ productId: farmableProProduct }];

	const handleSubmit = useCallback(
		({
			reportInput,
			reportFormat,
		}: {
			reportInput: ReportFormInterface;
			reportFormat: TReportFormat;
		}) => {
			const reportBody = makeReportBody(reportInput, reportType!);
			trackExport(reportFormat);
			const reportName = truncateString(
				`${location?.name} - ${format(new Date(), 'yyyy-MM-dd')}`,
				MAX_REPORT_NAME_LENGTH
			);
			enqueueSnackbar(t('logsPages.reports.generationStarted'), {
				variant: 'info',
			});

			getAccessTokenSilently()
				.then((accessToken) => {
					if (location?.id && accessToken && reportType) {
						void generateReport({
							request: reportBody,
							reportName,
							locationID: location?.id,
							reportType,
							reportFormat,
							accessToken: `Bearer ${accessToken}`,
						}).catch();
					}
				})
				.catch((error) => {
					enqueueSnackbar(t('logsPages.reports.generationFailed'), {
						variant: 'error',
					});
					if (error instanceof Error) {
						throwErrorSilently(error);
					}
				});
		},
		[
			getAccessTokenSilently,
			location?.id,
			location?.name,
			reportType,
			t,
			trackExport,
		]
	);

	return (
		<>
			<Box>
				{hasValidSubscription ? (
					<Button
						id="export-jobs-button"
						variant="outlined"
						onClick={toggleMenu}
					>
						{t('logsPages.jobLogsPage.exportButton.createReport')}
					</Button>
				) : (
					<SubscriptionModal
						title={t('logsPages.jobLogsPage.subscriptionModal.title')}
						noPermissionsMessage={t(
							'logsPages.jobLogsPage.subscriptionModal.noPermissions.subTitle'
						)}
						onOpenCallback={openModal}
						hasPurchasePermission={canPurchase}
						productIdsWithDemo={productIdsWithDemo}
					/>
				)}
				<Menu.Menu
					id="export-jobs-menu"
					open={isMenuOpened}
					anchorEl={anchorEl}
					onClose={toggleMenu}
					anchorOrigin={{
						horizontal: 'right',
						vertical: 'bottom',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<Menu.Item
						key={ReportType.SPRAYING}
						onClick={() => {
							toggleModal(ReportType.SPRAYING);
						}}
					>
						{t('treatmentLogsExportPage.reportTitle.SPRAYING')}
					</Menu.Item>
					<Divider sx={{ marginY: '0.5rem' }} />
					<Menu.Item
						key={ReportType.FERTILIZER}
						onClick={() => {
							toggleModal(ReportType.FERTILIZER);
						}}
					>
						{t('treatmentLogsExportPage.reportTitle.FERTILIZER')}
					</Menu.Item>
					<Divider sx={{ marginY: '0.5rem' }} />
					<Menu.Item
						onClick={() => {
							toggleModal(ReportType.HARVEST);
						}}
					>
						{t('treatmentLogsExportPage.reportTitle.HARVEST')}
					</Menu.Item>
					<Divider sx={{ marginY: '0.5rem' }} />
					<Menu.Item
						onClick={() => {
							toggleModal(ReportType.PRODUCT_INVENTORY);
						}}
					>
						{t('treatmentLogsExportPage.reportTitle.PRODUCT_INVENTORY')}
					</Menu.Item>
				</Menu.Menu>
			</Box>
			{!!reportType && (
				<CreateReportModal
					reportType={reportType}
					onClose={() => toggleModal(undefined)}
					onSubmit={handleSubmit}
				/>
			)}
		</>
	);
};
