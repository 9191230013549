import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TStockProductInventory } from 'Models/inventory/ProductsInventory';

export type TGetProductInventoryResponse = {
	getStockProductInventories: {
		totalPages: number;
		totalElements: number;
		content: TStockProductInventory[];
	};
};

const GET_CURRENT_INV_TABLE_DATA: TypedDocumentNode<
	TGetProductInventoryResponse,
	{
		locationId: string;
		pageInput: TPageInput;
		hasPagination: boolean;
		filterBy: {
			productName?: string;
		};
	}
> = gql`
	query GetStockProductInventories(
		$locationId: ID!
		$filterBy: StockProductInventoryFilterInput
		$pageInput: PageInput!
	) {
		getStockProductInventories(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			content {
				id
				product {
					id
					name
					createdAt
					discriminator
				}
				productWeight
				productUnit
				productCost
				currency
			}
		}
	}
`;

export default GET_CURRENT_INV_TABLE_DATA;
