import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_COMPANY_ACCESSES_DATA } from 'Features/api/getCompanyDataAccesses';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { PartnershipCompanyType } from 'Models/partnershipCompany/isPartnershipCompany';

import usePartnerCompany from 'Hooks/usePartnerCompany';

import NavbarLink from '../../NavbarLink';

const PartnerNavigationItems = () => {
	const { company } = usePartnerCompany();

	const { t } = useTranslation();

	const { data } = useQuery(GET_COMPANY_ACCESSES_DATA);
	const isAdvisorCompany = company?.type === PartnershipCompanyType.ADVISOR;

	const hasJobsAccess = data?.getCompanyDataAccesses.some(
		(companyData) => companyData.jobs
	);
	const hasHarvestAccess = data?.getCompanyDataAccesses.some(
		(companyData) => companyData.harvest
	);
	const hasSalesAccess = data?.getCompanyDataAccesses.some(
		(companyData) => companyData.sales
	);

	const defaultLinkForLogsForManager = hasJobsAccess
		? 'jobs'
		: hasHarvestAccess
		? 'harvest'
		: hasSalesAccess
		? 'sales'
		: null;

	if (!company) {
		return null;
	}

	const dashboardLink = (
		<NavbarLink
			label={t('common.navbar.dashboard')}
			link={`/partners/${company?.id}/dashboard`}
			checkActiveHandler={(_, location) =>
				location.pathname.includes('dashboard')
			}
		/>
	);

	const logsLink = (
		<NavbarLink
			label={t('common.navbar.logs')}
			link={`/partners/${company.id}/logs/${
				isAdvisorCompany ? defaultLinkForLogsForManager : 'jobs'
			}`}
			checkActiveHandler={(_, location) => location.pathname.includes('logs')}
		/>
	);

	const plannerLink = (
		<NavbarLink
			label={t('common.navbar.planning')}
			link={`/partners/${company.id}/planner`}
			checkActiveHandler={(_, location) =>
				location.pathname.includes('planner')
			}
		/>
	);

	return (
		<>
			{dashboardLink}
			{isAdvisorCompany && !!defaultLinkForLogsForManager ? null : logsLink}
			{plannerLink}
		</>
	);
};

export default PartnerNavigationItems;
