import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TStockProductInventory } from 'Models/inventory/ProductsInventory';
import LogsTableHeaderValues from 'Models/jobs/jobLogsTableData';
import Table from 'UI/display/Table';

import getCurrentInvLogsRowData from '../../../utils/getCurrentInvLogsRowData';

type THoursLogsTableRowProps = {
	product: TStockProductInventory;
};

const TableRow = (props: THoursLogsTableRowProps) => {
	const { product } = props;
	const { i18n } = useTranslation();

	return (
		<Table.Row hover>
			{getCurrentInvLogsRowData(product, i18n.language).map((cell, index) => (
				<Table.Cell key={`cell-${LogsTableHeaderValues[index] || index}`}>
					{cell}
				</Table.Cell>
			))}
		</Table.Row>
	);
};

export default TableRow;
