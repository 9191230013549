import {
	ApolloCache,
	DefaultContext,
	gql,
	MutationUpdaterFunction,
	TypedDocumentNode,
} from '@apollo/client';

import GET_PARTNER_TREATMENT_LIBRARIES from '../../PartnerTreatmentsPage/api/getPartnerTreatmentLibraries';

type TDeleteTreatmentLibraryResponse = {
	deleteTreatmentLibrary: boolean;
};

type TDeleteTreatmentLibraryInput = {
	libraryId: string;
};

export const DELETE_TREATMENT_LIBRARY: TypedDocumentNode<
	TDeleteTreatmentLibraryResponse,
	TDeleteTreatmentLibraryInput
> = gql`
	mutation DeleteTreatmentLibrary($libraryId: ID!) {
		deleteTreatmentLibrary(id: $libraryId)
	}
`;

export const removeDeletedLibraryFromCache: MutationUpdaterFunction<
	TDeleteTreatmentLibraryResponse,
	TDeleteTreatmentLibraryInput,
	DefaultContext,
	ApolloCache<unknown>
> = (cache, { data }, options) => {
	const query = { query: GET_PARTNER_TREATMENT_LIBRARIES };
	const deletedLibraryId = options.variables?.libraryId;
	const existingQuery = cache.readQuery(query)?.getTreatmentLibraries;
	const existingLibraries =
		cache.readQuery(query)?.getTreatmentLibraries.content;

	if (existingLibraries && deletedLibraryId && existingQuery) {
		cache.writeQuery({
			...query,
			data: {
				getTreatmentLibraries: {
					...existingQuery,
					content: [
						...existingLibraries.filter(
							(content) => content.id !== deletedLibraryId
						),
					],
				},
			},
		});
	}
};
