import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import {
	TMovementType,
	TProductInventoryLog,
} from 'Models/inventory/ProductInventoryLog';

export type TInventoryMovementTableData = Pick<
	TProductInventoryLog,
	| 'id'
	| 'date'
	| 'type'
	| 'productName'
	| 'productWeightChange'
	| 'productUnit'
	| 'productCostChange'
	| 'currency'
>;

const GET_INV_MOVEMENT_TABLE_DATA: TypedDocumentNode<
	{
		getProductInventoryMovements: {
			totalPages: number;
			totalElements: number;
			content: TInventoryMovementTableData[];
		};
	},
	{
		locationId: string;
		pageInput: TPageInput;
		hasPagination: boolean;
		filterBy: {
			productName?: string;
			dateFrom?: Date;
			dateTo?: Date;
			movementTypes?: TMovementType[];
		};
	}
> = gql`
	query GetProductInventoryMovements(
		$locationId: ID!
		$filterBy: ProductInventoryMovementFilterInput
		$pageInput: PageInput!
	) {
		getProductInventoryMovements(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			content {
				id
				date
				type
				productName
				productWeightChange
				productUnit
				productCostChange
				currency
			}
		}
	}
`;

export default GET_INV_MOVEMENT_TABLE_DATA;
