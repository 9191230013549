import React from 'react';

import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';

import usePermission from 'Hooks/usePermission';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import PageAccessValidator from '../../../../components/PageAccessValidator';
import ExportToFile from './components/ExportToFile';
import NoAccessPage from './components/NoAccessPage';

function HarvestExportPage() {
	const hasHarvestExportPermission = usePermission(Permissions.HARVEST_EXPORT);

	const hasTimesheetsSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.TIMESHEET
	);
	const hasCuadernoDigitalSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.CUADERNO_DIGITAL
	);
	const hasValidSubscription =
		hasTimesheetsSubscription || hasCuadernoDigitalSubscription;

	const hasPageAccess = Boolean(
		hasHarvestExportPermission && hasValidSubscription
	);

	return (
		<PageAccessValidator
			displayContent={hasPageAccess}
			fallback={<NoAccessPage />}
		>
			<ErrorBoundary>
				<ExportToFile />
			</ErrorBoundary>
		</PageAccessValidator>
	);
}

export default HarvestExportPage;
