import { TTranslateFunction } from 'Features/localization';
import toCurrencyDisplay from 'Features/localization/utils/toCurrencyDisplay';
import classicFormat from 'Models/dates/classicFormat';
import roundToTwoDecimal from 'Models/units/roundToTwoDecimal';
import { toDisplayString } from 'Models/units/transpileUnits';

import { TInventoryMovementTableData } from '../api/getInventoryMovementTableData';

function getInventoryMovementLogsRowData(
	movement: TInventoryMovementTableData,
	t: TTranslateFunction,
	locale: Locale['code']
) {
	return Object.values({
		date: movement.date ? classicFormat(new Date(movement.date)) : '-',
		movementType: t(`movementType.${movement.type}`),
		productName: movement.productName,
		amount: roundToTwoDecimal(movement.productWeightChange),
		unit: toDisplayString(movement.productUnit),
		totalValue: toCurrencyDisplay(
			movement.productCostChange,
			locale,
			movement.currency
		),
	});
}

export default getInventoryMovementLogsRowData;
