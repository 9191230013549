import { gql, TypedDocumentNode } from '@apollo/client';
import { TProductInventory } from 'Models/inventory/ProductsInventory';

export type TGetProductInventoryResponse = {
	getStockProductInventories: {
		totalPages: number;
		totalElements: number;
		content: Pick<TProductInventory, 'product'>[];
	};
};

export const GET_INVENTORY_PRODUCTS: TypedDocumentNode<
	TGetProductInventoryResponse,
	{
		locationId: string;
	}
> = gql`
	query GetStockProductInventories($locationId: ID!) {
		getStockProductInventories(locationId: $locationId) {
			totalPages
			totalElements
			content {
				product {
					id
					name
				}
			}
		}
	}
`;
