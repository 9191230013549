import React from 'react';

import { useQuery } from '@apollo/client';
import ISOToLocal from 'Models/dates/ISOToLocal';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_INV_MOVEMENT_TABLE_DATA from '../api/getInventoryMovementTableData';

const Pagination = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const { data, loading } = useQuery(GET_INV_MOVEMENT_TABLE_DATA, {
		variables: {
			filterBy: {
				dateFrom: searchParams.filters.dateFrom
					? ISOToLocal(searchParams.filters.dateFrom)
					: undefined,
				dateTo: searchParams.filters.dateTo
					? ISOToLocal(searchParams.filters.dateTo)
					: undefined,
				movementTypes: searchParams.filters.movementTypes,
				productName: searchParams.filters.search,
			},
			pageInput: searchParams.pageInput,
			locationId: searchParams.locationID,
			hasPagination: true,
		},
	});

	return (
		<TablePagination
			count={data?.getProductInventoryMovements.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
