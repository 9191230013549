import { TStockUnits } from '../units';

export const MovementType = {
	PURCHASE: 'PURCHASE',
	RECOUNT: 'RECOUNT',
	DISPOSE: 'DISPOSE',
	CONSUMPTION: 'CONSUMPTION',
} as const;

export type TMovementType = keyof typeof MovementType;

export const ALL = Object.values(MovementType)
	// used type guard to get appropriate type for validator
	.filter((value): value is TMovementType => typeof value === 'string');

export type TProductInventoryLog = {
	id: string;
	type: TMovementType;
	date: string;
	productName: string;
	productWeightChange: number;
	productUnit: TStockUnits;
	productCostChange: number;
	currency: string;
};
