import React from 'react';

import { analyticsTrack } from 'Features/analytics';
import { TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import usePermission from 'Hooks/usePermission';
import useProfile from 'Hooks/useProfile';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import SubscriptionModal from '../../../../components/SubscriptionModal';
import ExportButton from './ExportButton';

const ExportButtonValidator = () => {
	const { profile } = useProfile();
	const location = useCurrentLocation();

	const { t } = useTranslation();

	const hasCuadernoDigitalSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.CUADERNO_DIGITAL
	);
	const hasFarmableProSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.FARMABLE_PRO
	);
	const hasValidSubscription =
		hasCuadernoDigitalSubscription || hasFarmableProSubscription;

	const canPurchase = usePermission(Permissions.JOB_LOGS_PURCHASE);

	const cuadernoDigitalStripeProduct =
		process.env.STRIPE_CUADERNO_DIGITAL_PRODUCT;
	const farmableProProduct = process.env.STRIPE_FARMABLE_PRO_PRODUCT;

	function openModal(): void {
		if (profile && location) {
			analyticsTrack(TrackEvents.ProductViewed, {
				userId: profile.id,
				groupId: location.id,
				farmName: location.name,
				moduleName:
					location.type === 'SIEX'
						? SubscriptionType.CUADERNO_DIGITAL
						: SubscriptionType.FARMABLE_PRO,
			});
		}
	}
	if (!cuadernoDigitalStripeProduct || !farmableProProduct) {
		return null;
	}

	const productIdsWithDemo =
		location?.type === 'SIEX'
			? [{ productId: cuadernoDigitalStripeProduct }]
			: [{ productId: farmableProProduct }];

	return (
		<>
			{hasValidSubscription ? (
				<ExportButton />
			) : (
				<SubscriptionModal
					title={t('logsPages.jobLogsPage.subscriptionModal.title')}
					noPermissionsMessage={t(
						'logsPages.jobLogsPage.subscriptionModal.noPermissions.subTitle'
					)}
					onOpenCallback={openModal}
					hasPurchasePermission={canPurchase}
					productIdsWithDemo={productIdsWithDemo}
				/>
			)}
		</>
	);
};

export default ExportButtonValidator;
