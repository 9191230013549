import React, { Fragment, ReactElement } from 'react';

import { TTranslateFunction } from 'Features/localization';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getTooltipForActiveField from 'Models/fields/getTooltipForActiveField';
import { TField } from 'Models/fields/isField';
import { TJobWithRunStatusLog } from 'Models/jobs/getRunningJobs';
import { TActiveJob } from 'Models/jobs/isActiveJob';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Text from 'UI/display/Text';
import Tooltip from 'UI/maps/Tooltip';

import useSubscriptionsFeatureFlag from '../../hooks/useSubscriptionsFeatureFlag';
import FieldPolygon from './FieldPolygon';
import SafeSprayingMarker from './SafeSprayingMarker';

export type TFieldSearchMap = Pick<
	TField,
	| 'id'
	| 'name'
	| 'geometry'
	| 'reentryAllowedDate'
	| 'harvestAllowedDate'
	| 'varieties'
	| 'areaSize'
>;

type TFieldLayersProps = {
	fields: TFieldSearchMap[];
	runningJobs?: (TActiveJob & TJobWithRunStatusLog)[];
	currentFieldId?: string | null;
	showFieldName?: boolean;
	onFieldClick?: (field: Pick<TFieldSearchMap, 'id'>) => void;
};

const getTooltip = (
	field: TFieldSearchMap,
	t: TTranslateFunction,
	runningJobs?: (TActiveJob & TJobWithRunStatusLog)[],
	showFieldName?: boolean,
	onClick?: () => void
) => {
	const tooltipContent: ReactElement[] = [];

	if (showFieldName) {
		tooltipContent.push(
			<Tooltip
				direction="center"
				permanent
				variant="noBackground"
				key={`farm-name-tooltip-${field.name}`}
			>
				<Text
					onClick={onClick}
					variant="secondaryBody2"
					overrideMUIColor="WHITE"
					sx={{
						pointerEvents: 'auto',
						cursor: 'pointer',
						textShadow: '0 0 1px #000',
						fontWeight: 500,
					}}
				>
					{field.name || ''}
				</Text>
			</Tooltip>
		);
	}

	if (runningJobs && runningJobs.length) {
		tooltipContent.push(
			<Tooltip
				direction="top"
				permanent
				key={`active-field-${field.name}-tooltip`}
			>
				{getTooltipForActiveField(runningJobs, field, t)}
			</Tooltip>
		);
	}

	return tooltipContent;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FieldLayers = React.forwardRef((props: TFieldLayersProps, ref) => {
	const { fields, currentFieldId, runningJobs, showFieldName, onFieldClick } =
		props;
	const { t } = useTranslation();

	const hasFarmableProSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.FARMABLE_PRO
	);

	const fieldViews = fields
		? fields.map((field) => {
				const safeSprayingMarker = hasFarmableProSubscription ? (
					<SafeSprayingMarker field={field} />
				) : null;

				const onFieldOrLabelClick = () =>
					onFieldClick ? onFieldClick(field) : null;

				return (
					<Fragment key={field.id}>
						<FieldPolygon
							onClick={onFieldOrLabelClick}
							field={field}
							status={currentFieldId === field.id ? 'FOCUSED' : 'NOT_FOCUSED'}
							tooltipContent={getTooltip(
								field,
								t,
								runningJobs,
								showFieldName,
								onFieldOrLabelClick
							)}
						/>
						{safeSprayingMarker}
					</Fragment>
				);
		  })
		: null;

	return <>{fieldViews}</>;
});

export default FieldLayers;
