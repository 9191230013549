import {
	GRAM,
	KILOGRAM,
	TON,
	POUND,
	GALLON,
	OUNCE,
	GRAM_PER_SQUARE_METER,
	KILOGRAM_PER_SQUARE_METER,
	LITER_PER_SQUARE_METER,
	MILLILITER_PER_SQUARE_METER,
	GRAM_PER_1000_SQUARE_METER,
	KILOGRAM_PER_1000_SQUARE_METER,
	LITER_PER_1000_SQUARE_METER,
	MILLILITER_PER_1000_SQUARE_METER,
	GRAM_PER_HECTARE,
	KILOGRAM_PER_HECTARE,
	LITER_PER_HECTARE,
	MILLILITER_PER_HECTARE,
	GRAM_PER_LITER_WATER,
	LITER_PER_LITER_WATER,
	LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT,
	KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT,
	KILOGRAM_PER_1000_LITER_WATER,
	LITER_PER_1000_LITER_WATER,
	GRAM_PER_100_LITER,
	MILLILITER_PER_100_LITER,
	TON_PER_HECTARE,
	POUND_PER_ACRE,
	GALLON_PER_ACRE,
	OUNCE_PER_ACRE,
	GRAM_PER_PLANT,
	OUNCE_PER_PLANT,
	MILLILITER_PER_PLANT,
	FLUID_OUNCE_PER_PLANT,
	AreaUnits,
	VolumeUnits,
	WaterConsumptionUnits,
	JobDistanceUnits,
	SalesVolumeUnits,
	TProductUnits,
	US_GALLON,
	IMPERIAL_GALLON,
	US_FLUID_OUNCE,
	IMPERIAL_FLUID_OUNCE,
} from '.';

const EN_DISPLAY_STRING_TABLE = {
	[GRAM]: 'g',
	[KILOGRAM]: 'kg',
	[TON]: 't',
	[POUND]: 'lbs',
	[GALLON]: 'gal',
	[OUNCE]: 'oz',
	[US_GALLON]: 'US gal',
	[IMPERIAL_GALLON]: 'Imp gal',
	[US_FLUID_OUNCE]: 'US fl oz',
	[IMPERIAL_FLUID_OUNCE]: 'Imp fl oz',
	[VolumeUnits.LITER]: 'L',
	[VolumeUnits.MILLILITER]: 'mL',
	[AreaUnits.HECTARE]: 'ha',
	[AreaUnits.DECARE]: 'daa',
	[AreaUnits.ACRES]: 'ac',
	[AreaUnits.SQUARE_METERS]: 'm²',
	[JobDistanceUnits.KILOMETERS]: 'km',
	[JobDistanceUnits.MILES]: 'mi',
	[SalesVolumeUnits.THOUSANDS_OF_POUNDS]: 'k lbs',

	[WaterConsumptionUnits.LITERS_PER_HECTARE]: 'L/ha',
	[WaterConsumptionUnits.GALLONS_PER_ACRE]: 'gal/ac',

	[GRAM_PER_SQUARE_METER]: 'g/m²',
	[LITER_PER_SQUARE_METER]: 'L/m²',
	[KILOGRAM_PER_SQUARE_METER]: 'kg/m²',
	[MILLILITER_PER_SQUARE_METER]: 'mL/m²',

	[GRAM_PER_1000_SQUARE_METER]: 'g/1000m²',
	[KILOGRAM_PER_1000_SQUARE_METER]: 'kg/1000m²',
	[LITER_PER_1000_SQUARE_METER]: 'L/1000m²',
	[MILLILITER_PER_1000_SQUARE_METER]: 'mL/1000m²',

	[GRAM_PER_HECTARE]: 'g/ha',
	[KILOGRAM_PER_HECTARE]: 'kg/ha',
	[LITER_PER_HECTARE]: 'L/ha',
	[MILLILITER_PER_HECTARE]: 'mL/ha',
	[LITER_PER_HECTARE_AND_METER_CROWN_HEIGHT]: 'L/ha and per m crown height',
	[KILOGRAM_PER_HECTARE_AND_METER_CROWN_HEIGHT]: 'kg/ha and per m crown height',

	[GRAM_PER_LITER_WATER]: 'g/L water',
	[LITER_PER_LITER_WATER]: 'L/L water',
	[KILOGRAM_PER_1000_LITER_WATER]: 'kg/L water',
	[LITER_PER_1000_LITER_WATER]: 'L/1000L water',
	[GRAM_PER_100_LITER]: 'g/100L',
	[MILLILITER_PER_100_LITER]: 'mL/100L',

	[TON_PER_HECTARE]: 't/ha',
	[POUND_PER_ACRE]: 'lbs/ac',
	[GALLON_PER_ACRE]: 'gal/ac',
	[OUNCE_PER_ACRE]: 'oz/ac',

	[GRAM_PER_PLANT]: 'g/plant',
	[OUNCE_PER_PLANT]: 'ml/plant',
	[MILLILITER_PER_PLANT]: 'oz/plant',
	[FLUID_OUNCE_PER_PLANT]: 'fl oz/plant',
};

const TABLES = {
	en: EN_DISPLAY_STRING_TABLE,
	default: EN_DISPLAY_STRING_TABLE,
};

const SHORT_APP_RATE_UNIT = {
	milliliters: 'mL',
	grams: 'g',
	tons: 't',
	kg: 'kg',
	liters: 'L',
	gal: 'gal',
	lbs: 'lbs',
	oz: 'oz',
	'liters/hectare': 'L/ha',
	'kg/hectare': 'kg/ha',
	'lbs/acre': 'lbs/ac',
	'gal/acre': 'gal/ac',
};

export const toDisplayString = (unit: string, locale?: string): string => {
	const table = locale ? TABLES[locale] : TABLES.default;
	const defaultTable = TABLES.default;

	return table[unit] || defaultTable[unit] || unit || '-';
};

export const toDisplayUnit = (unit: string, locale?: string): TProductUnits => {
	const table = locale ? TABLES[locale] : TABLES.default;
	const displayUnit = Object.keys(table).find(
		(key): key is TProductUnits => table[key] === unit
	);

	if (displayUnit) {
		return displayUnit;
	} else {
		throw Error('Cannot find appropriate unit value.');
	}
};

export const convertToShortRateUnit = (unit: string) => {
	const shortUnitName = EN_DISPLAY_STRING_TABLE[unit];

	if (shortUnitName) {
		return shortUnitName;
	} else {
		throw Error('Cannot find appropriate application rate unit value.');
	}
};
