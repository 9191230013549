import React, { FC, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { ReportType } from 'Models/exports/Report';
import Permissions from 'Models/permissions/Permissions';
import Box from 'UI/layout/Box';
import _flattenDeep from 'lodash/flattenDeep';

import usePermission from 'Hooks/usePermission';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';

import { getCategoriesWithIdsFromFields } from '../../../../../components/filters/Categories/utils/getCategoriesFromFields';
import { GET_TEAMS_MEMBERS_QUERY } from '../../../../../components/filters/TeamMembers/api/getTeamMembers';
import { GET_TEAMS_QUERY } from '../../../../../components/filters/Teams/api/getTeams';
import {
	GET_FIELDS_ADVISOR_QUERY,
	GET_FIELDS_QUERY,
} from '../../../../../components/filters/common/api/getFields';
import parseGetFieldsResult from '../../../../../components/filters/common/utils/parseGetFieldsResult';
import { GET_INVENTORY_PRODUCTS } from '../../../CurrentInventoryLogsPage/api/getInventoryProducts';
import {
	CREATE_REPORT_TAB_STATE,
	ReportFormInterface,
	TCreateReportType,
} from '../../CreateReport.types';
import { CreateReportModalForm } from './CreateReportModalForm';
import { CreateReportModalSummary } from './CreateReportModalSummary';

interface CreateReportModalBodyInterface {
	reportInput: ReportFormInterface;
	reportType: TCreateReportType;
	onChange: (value: ReportFormInterface) => void;
	tabState: CREATE_REPORT_TAB_STATE;
}
export const CreateReportModalBody: FC<CreateReportModalBodyInterface> = ({
	reportInput,
	onChange,
	tabState,
	reportType,
}) => {
	const locationId = getLocationIdFromUrl();
	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY
	);
	const { data } = useQuery(
		hasPermissionsToRegularQuery ? GET_FIELDS_QUERY : GET_FIELDS_ADVISOR_QUERY,
		{
			variables: {
				locationId: locationId,
			},
		}
	);

	const { data: teamsData } = useQuery(GET_TEAMS_QUERY, {
		variables: {
			locationId: locationId,
		},
		skip: reportType !== ReportType.TIMESHEET,
	});

	const { data: teamsMembersData } = useQuery(GET_TEAMS_MEMBERS_QUERY, {
		variables: {
			locationId: locationId,
			filterBy: reportInput.teamId?.length
				? {
						teamIds: reportInput.teamId,
				  }
				: {},
		},
		skip: reportType !== ReportType.TIMESHEET,
	});

	const { data: inventoryProducts } = useQuery(GET_INVENTORY_PRODUCTS, {
		variables: {
			locationId: locationId,
		},
	});
	const productInventoryOptions = useMemo(
		() =>
			inventoryProducts?.getStockProductInventories.content.map(
				({ product: { id, name } }) => ({
					id,
					label: name,
				})
			) || [],
		[inventoryProducts?.getStockProductInventories.content]
	);

	const teamsOptions = useMemo(
		() =>
			teamsData?.getTeams?.map((team) => ({
				label: team.name,
				id: team.id,
			})),
		[teamsData?.getTeams]
	);

	const teamMembersOptions = useMemo(() => {
		const selectedTeams = teamsData?.getTeams.filter(({ id }) =>
			reportInput.teamId.includes(id)
		);
		return selectedTeams?.length
			? _flattenDeep(selectedTeams?.map(({ teamMembers }) => teamMembers)).map(
					(teamMember) => ({
						label: teamMember.fullName,
						id: teamMember.id,
					})
			  )
			: teamsMembersData?.getTeamMembersPageable.teamMembers.map(
					({ id, fullName }) => ({
						label: fullName,
						id,
					})
			  );
	}, [
		reportInput.teamId,
		teamsData?.getTeams,
		teamsMembersData?.getTeamMembersPageable.teamMembers,
	]);

	const categoriesOptions = useMemo(
		() =>
			getCategoriesWithIdsFromFields(parseGetFieldsResult(data)).map(
				({ id, name }) => ({
					id,
					label: name,
				})
			),
		[data]
	);

	const filteredFields = parseGetFieldsResult(data);

	const qualityOptions = useMemo(
		() => ['1', '2', '3', '4', '5'].map((elem) => ({ id: elem, label: elem })),
		[]
	);

	return (
		<Box>
			{tabState === CREATE_REPORT_TAB_STATE.form ? (
				<CreateReportModalForm
					reportInput={reportInput}
					onChange={onChange}
					filteredFields={filteredFields}
					categoriesOptions={categoriesOptions}
					teamMembersOptions={teamMembersOptions}
					productInventoryOptions={productInventoryOptions}
					teamsOptions={teamsOptions}
					qualityOptions={qualityOptions}
					reportType={reportType}
				/>
			) : (
				<CreateReportModalSummary
					reportInput={reportInput}
					reportType={reportType}
					filteredFields={filteredFields}
					productInventoryOptions={productInventoryOptions}
				/>
			)}
		</Box>
	);
};
