import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import NoLogsStatement from '../../../../common/components/NoLogsStatement';
import GET_CURRENT_INV_TABLE_DATA from '../../../api/getCurrentInventoryTableData';
import TableRow from './TableRow';

const TableBody = () => {
	const { paramsFromURL: searchParams } = useManageHistory();

	const { data, loading, error } = useQuery(GET_CURRENT_INV_TABLE_DATA, {
		variables: {
			filterBy: {
				productName: searchParams.filters.search,
			},
			pageInput: searchParams.pageInput,
			locationId: searchParams.locationID,
			hasPagination: true,
		},
	});

	if (loading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		if (!data?.getStockProductInventories.content.length) {
			return (
				<NoDataTableContainer>
					<NoLogsStatement logsType="CurrentInventory" />
				</NoDataTableContainer>
			);
		}

		return (
			<Table.Body>
				{data.getStockProductInventories.content.map((product) => (
					<TableRow key={product.product.id} product={product} />
				))}
			</Table.Body>
		);
	}

	return null;
};

export default TableBody;
