import toCurrencyDisplay from 'Features/localization/utils/toCurrencyDisplay';
import { TStockProductInventory } from 'Models/inventory/ProductsInventory';
import roundToTwoDecimal from 'Models/units/roundToTwoDecimal';
import { toDisplayString } from 'Models/units/transpileUnits';

function getCurrentInvLogsRowData(
	product: TStockProductInventory,
	locale: Locale['code']
) {
	return Object.values({
		productName: product.product.name,
		amount: roundToTwoDecimal(product.productWeight),
		unit: toDisplayString(product.productUnit),
		totalValue: toCurrencyDisplay(
			product.productCost,
			locale,
			product.currency
		),
	});
}

export default getCurrentInvLogsRowData;
